import React, { useState, useEffect } from "react";
import "./ContactUs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope, faMapMarkerAlt, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter, faLinkedin, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import Footer from "../../include/Footer";
import axios from "axios";
import config from "../../include/config";

import { faPaperPlane, faRobot, faUser } from "@fortawesome/free-solid-svg-icons";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    company: "",
    message: "",
  });

  const [responseMessage, setResponseMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);


  const quickReplies = [
    "Hello",
    "Company Details",
    "Services",
    "Contact Information",
    "Careers",
  ];

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleChatSend();
    }
  };

  useEffect(() => {
    const welcomeMessage = {
      sender: "bot",
      text: "Hi there! Welcome to Encoders Software Pvt Ltd. How can I assist you today?",
    };
    setChatMessages([welcomeMessage]);
  }, []);

  const handleChatSend = () => {
    if (!chatInput.trim()) return;

    const userMessage = { sender: "user", text: chatInput.trim() };
    setChatMessages((prev) => [...prev, userMessage]);

    setIsTyping(true);
    setTimeout(() => {
      const botResponse = generateBotResponse(chatInput.trim());
      const botMessage = { sender: "bot", text: botResponse };
      setChatMessages((prev) => [...prev, botMessage]);
      setIsTyping(false);
    }, 1000);

    setChatInput("");
  };

  const handleQuickReply = (reply) => {
    const userMessage = { sender: "user", text: reply };
    setChatMessages((prev) => [...prev, userMessage]);

    setIsTyping(true);
    setTimeout(() => {
      const botResponse = generateBotResponse(reply);
      const botMessage = { sender: "bot", text: botResponse };
      setChatMessages((prev) => [...prev, botMessage]);
      setIsTyping(false);
    }, 1000);
  };

  const generateBotResponse = (input) => {
    const lowerInput = input.toLowerCase();
    if (lowerInput.includes("hello")) return "Hello! How can I help you today?";
    if (lowerInput.includes("hi")) return "Hello! How can I help you today?";
    if (lowerInput.includes("HI")) return "Hello! How can I help you today?";
    if (lowerInput.includes("HELLO")) return "Hello! How can I help you today?";
    if (lowerInput.includes("company details"))
      return "We are Encoders Software Pvt Ltd, established in 2019. We specialize in IT solutions. What else would you like to know?";
    if (lowerInput.includes("services"))
      return "We provide services like Website Development, Mobile Apps, and SaaS products. Let us know your needs!";
    if (lowerInput.includes("contact"))
      return "You can contact us at support@encoderssoftware.com or call us at +91 7258803502.";
    if (lowerInput.includes("careers"))
      return "We are hiring! Check our Careers page for exciting opportunities.";
    return "I'm sorry, I didn't understand that. Can you ask something else?";
  };

  // Form Handlers
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { name, email, mobile, message } = formData;
  
    // Validation
    if (!name || !email || !mobile || !message) {
      setResponseMessage("All fields marked with * are required.");
      return;
    }
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setResponseMessage("Please enter a valid email address.");
      return;
    }
  
    try {
      // Make the API call
      const response = await axios.post(
        config.BASE_URL+":8443/api/contact/sendMessage",
        formData
      );
      setResponseMessage(response.data); // Display success message
      setFormData({
        name: "",
        email: "",
        mobile: "",
        company: "",
        message: "",
      });
    } catch (error) {
      console.error("Error sending message:", error);
      setResponseMessage("Failed to send the message. Please try again later.");
    }
  };
  // Chatbox Handlers
  

  const handleChatKeyPress = (e) => {
    if (e.key === "Enter") {
      handleChatSend();
    }
  };

 

  return (
    <div className="contact-us">
      {/* Banner Section */}
      <div className="banner">
        <img
          src="/images/big-images/banner_contact.jpg"
          alt="Contact Banner"
          className="banner-image"
        />
        <div className="banner-overlay">
          <h1>Contact Us</h1>
          <p>We’re here to help. Reach out with any queries or feedback.</p>
        </div>
      </div>

      {/* Main Section */}
      <div className="contact-container">
        {/* Company Details */}
        <div className="contact-details">
          <h2>Company Details</h2>
          <p>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" /> Flat No 8 F, Pundag Road, Ranchi, Jharkhand 834002
          </p>
          <p>
            <FontAwesomeIcon icon={faPhone} className="icon" />{" "}
            <a href="tel:+917258803502" className="clickable">
              +91 7258803502
            </a>
          </p>
          <p>
            <FontAwesomeIcon icon={faEnvelope} className="icon" />{" "}
            <a
              href="mailto:support@encoderssoftware.com"
              className="clickable"
            >
              support@encoderssoftware.com
            </a>
          </p>
          <div className="social-links">
            <a href="#" className="social-icon">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="#" className="social-icon">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="#" className="social-icon">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="#" className="social-icon">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://wa.me/917258803502"
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </div>
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3681.137319903208!2d85.3051763153841!3d23.35322608474431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f39ecdd1e0768b%3A0xd16a2fa4f6c43328!2sFlat%20No%208%20F%2C%20Pundag%20Rd%2C%20Ranchi%2C%20Jharkhand%20834002!5e0!3m2!1sen!2sin!4v1663340766586!5m2!1sen!2sin"
              width="100%"
              height="200"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>

        {/* Contact Form */}
        <div className="contact-form">
          <h2>Send Us a Message</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Your Name *"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email Address *"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="mobile"
              placeholder="Phone Number *"
              value={formData.mobile}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Your Message *"
              rows="5"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button type="submit" className="btn btn-submit">
              Send Message
            </button>
          </form>
          {responseMessage && <p className="response-message">{responseMessage}</p>}
        </div>

        {/* Chatbox Section */}
        <div className="chat-container">
      <h2 className="chat-title">Chat with Us</h2>
      <div className="chat-window">
        {chatMessages.map((msg, index) => (
          <div key={index} className={`chat-message ${msg.sender}`}>
            <div className="chat-avatar">
              {msg.sender === "bot" ? (
                <FontAwesomeIcon icon={faRobot} />
              ) : (
                <FontAwesomeIcon icon={faUser} />
              )}
            </div>
            <div className="chat-bubble">{msg.text}</div>
          </div>
        ))}
        {isTyping && (
          <div className="chat-typing-indicator">
            <FontAwesomeIcon icon={faRobot} /> Bot is typing...
          </div>
        )}
      </div>
     
      <div className="chat-input-container">
        <input
          type="text"
          placeholder="Type your message..."
          value={chatInput}
          onChange={(e) => setChatInput(e.target.value)}
          className="chat-input"
        />
        <button onClick={handleChatSend} className="chat-send-button">
          <FontAwesomeIcon icon={faPaperPlane} />
        </button>
        </div>
    </div>
      <Footer />
    </div>
    </div>
  )
};

export default ContactUs;
