import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLaptopCode,
  faMobileAlt,
  faCloud,
  faProjectDiagram,
  faServer,
  faTools,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faTwitter, faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* About Section */}
        <div className="footer-section about">
          <h3>About Us</h3>
          <p>
            <b>Encoders Software Private Limited</b> is a trusted IT solutions provider based in Ranchi, Jharkhand.
            We specialize in delivering innovative, scalable, and cost-effective digital solutions.
          </p>
          <p>
            Our services include web and mobile application development, cloud integration, government project
            support, SaaS products, and more. We are committed to transforming businesses through technology.
          </p>
        </div>

        {/* Services Section */}
        <div className="footer-section ">
          <h3>Our Services</h3>
          <ul className="service-list">
            <li>
              <FontAwesomeIcon icon={faLaptopCode} className="service-icon" />
              Custom Web Application Development
            </li>
            <li>
              <FontAwesomeIcon icon={faMobileAlt} className="service-icon" />
              Mobile Application Development (iOS & Android)
            </li>
            <li>
              <FontAwesomeIcon icon={faCloud} className="service-icon" />
              Cloud Integration and Migration
            </li>
            <li>
              <FontAwesomeIcon icon={faProjectDiagram} className="service-icon" />
              Government Project Support
            </li>
            <li>
              <FontAwesomeIcon icon={faServer} className="service-icon" />
              SaaS Product Development
            </li>
            <li>
              <FontAwesomeIcon icon={faTools} className="service-icon" />
              IT Consulting and Training
            </li>
            <li>
              <FontAwesomeIcon icon={faCogs} className="service-icon" />
              API Development and Integration
            </li>
          </ul>
        </div>

        {/* Contact Section */}
        <div className="footer-section contact">
          <h3>Contact Us</h3>
          <p>
            <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" /> Flat No 8F, Oak Forest, Pundag Road, Ranchi, Jharkhand, 834002
          </p>
          <p>
            <FontAwesomeIcon icon={faPhone} className="icon" /> +91 7258803502 & +91 9430796895
          </p>
          <p>
            <FontAwesomeIcon icon={faEnvelope} className="icon" />{" "}
            <a href="mailto:support@encoderssoftware.com">
              support@encoderssoftware.com
            </a>
          </p>
        </div>

        {/* Follow Us Section */}
        <div className="footer-section follow-us">
          <h3>Follow Us</h3>
          <p>Connect with us:</p>
          <div className="social-icons">
            <a href="#" title="Facebook">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a href="#" title="Twitter">
              <FontAwesomeIcon icon={faTwitter} />
            </a>
            <a href="#" title="LinkedIn">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <a href="#" title="Instagram">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>
          &copy; {new Date().getFullYear()} Encoders Software Private Limited. All rights reserved.{" "}
          <a href="/terms-of-use">Terms of Use</a> |{" "}
          <a href="/privacy-policy">Privacy Policy</a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
