import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./JobList.css";
import config from "../../include/config";

const JobList = ({ searchQuery, filters }) => {
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
  
    axios
      .get(config.BASE_URL+":8443/api/jobs") // Replace with your API endpoint
      .then((response) => setJobs(response.data))
      .catch((error) => console.error("Error fetching jobs:", error));
  }, []);

  const filteredJobs = jobs.filter((job) => {
    const title = job.title?.toLowerCase() || "";
    const location = job.location?.toLowerCase() || "";
    const jobType = job.job_type?.toLowerCase() || "";

    const matchesSearch =
      title.includes(searchQuery.toLowerCase()) ||
      location.includes(searchQuery.toLowerCase()) ||
      jobType.includes(searchQuery.toLowerCase());

    const matchesFilters =
      (!filters.remote || location.includes("remote")) &&
      (!filters.fullTime || jobType === "full-time");

    return matchesSearch && matchesFilters;
  });

  const handleApplyClick = (job) => {
    navigate("/apply", { state: { job } });
  };

  return (
    <div className="job-list">
      {filteredJobs.length === 0 ? (
        <p>No job openings match your filters.</p>
      ) : (
        filteredJobs.map((job) => (
          <div key={job.id} className="job-card">
            <h3>{job.title}</h3>
            <p>
              <strong>Location:</strong> {job.location || "Not specified"}
            </p>
            <p>
              <strong>Job Type:</strong> {job.job_type || "Not specified"}
            </p>
            <p>
              <strong>Posted On:</strong> {job.posted_on || "Not specified"}
            </p>
            <p>{job.description}</p>
            <button
              onClick={() => handleApplyClick(job)}
              className="apply-button"
            >
              Apply Now
            </button>
          </div>
        ))
      )}
    </div>
  );
};

export default JobList;
