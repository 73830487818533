import React, { useEffect } from 'react';
import './About.css';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaRegLightbulb, FaPencilRuler, FaCode, FaBug, FaCloudUploadAlt } from 'react-icons/fa';
import {
  faGlobe,
  faMobileAlt,
  faSms,
  faBriefcase,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import Footer from '../../include/Footer';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useInView } from 'react-intersection-observer';

const About = () => {

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/contact"); 
  };


  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      once: true, // Animate only once
    });
  }, []);

  return (
    <div className="about">
      {/* Hero Section */}
      <section className="hero-section">
        <img src="/images/big-images/10.jpg" alt="About Encoders Software" className="hero-image" />
        <div className="hero-overlay">
          <h1>About Us</h1>
          <p>Driving innovation and empowering businesses with cutting-edge technology solutions.</p>
        </div>
      </section>

      {/* About Company Section */}
      <section className="about-company">
        <div className="container">
          <h2 className="section-title">Welcome to Encoders Software</h2>
          <p className="section-content">
            At Encoders Software Private Limited, we specialize in delivering cost-effective IT solutions for businesses,
            educational institutions, and government organizations. With over 5 years of expertise, we provide a range
            of services, including website development, mobile app solutions, and customized business applications.
          </p>
          <p className="section-content">
            From real-world projects to skill development programs, we bridge the gap between industry needs and
            individual capabilities. Our team is committed to fostering innovation, driving digital transformation, and
            supporting businesses at every stage of their journey.
          </p>
        </div>
      </section>

      <div className="banner">
  <img src="/images/big-images/1.jpg" alt="Banner Background" className="banner-image" />
  <div className="banner-overlay">
    <div className="banner-content">
    <h1>Innovate. Transform. Succeed.</h1>
      <p>
        At Encoders Software, we empower businesses to achieve their full potential 
        through cutting-edge technology and tailored IT solutions. Let us be your 
        partner in digital transformation.
      </p>
    
    </div>
  </div>
</div>

      {/* Services Section */}
      <section className="services">
        <div className="container">
          <h2 className="section-title">Our Services</h2>
          <div className="services-list">
            <div className="service-item" data-aos="fade-up">
              <FontAwesomeIcon icon={faGlobe} className="service-icon" />
              <h4 className="service-title">Website Development</h4>
              <p className="service-content">
                Affordable, responsive websites with hosting, custom domains, and business email setups to enhance your
                online presence.
              </p>
            </div>
            <div className="service-item" data-aos="fade-up" data-aos-delay="100">
              <FontAwesomeIcon icon={faMobileAlt} className="service-icon" />
              <h4 className="service-title">Mobile App Development</h4>
              <p className="service-content">
                Intuitive and user-friendly mobile applications for iOS and Android to expand your reach.
              </p>
            </div>
            <div className="service-item" data-aos="fade-up" data-aos-delay="200">
              <FontAwesomeIcon icon={faSms} className="service-icon" />
              <h4 className="service-title">SMS & WhatsApp Integration</h4>
              <p className="service-content">
                Enhance customer engagement with integrated SMS and WhatsApp solutions tailored to your business needs.
              </p>
            </div>
            <div className="service-item" data-aos="fade-up" data-aos-delay="300">
              <FontAwesomeIcon icon={faBriefcase} className="service-icon" />
              <h4 className="service-title">ERP Solutions</h4>
              <p className="service-content">
                Comprehensive ERP software for streamlining operations in businesses, educational institutions, and
                healthcare organizations.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div className="project-delivery-row">
      {/* Section Title */}
      <h2 className="section-title">Our Project Delivery Flow</h2>
   

      {/* Responsive Layout */}
      <div className="row">
        {/* Left Column: Image */}
        <div className="image-column">
          <img
            src="/images/big-images/pf.png" // Replace with the correct image path
            alt="Project Delivery Infographic"
            className="delivery-image"
          />
        </div>

        {/* Right Column: Project Flow */}
        <div className="flow-column">
          <div className="project-flow">
            <div className="flow-steps">
              <div className="step fade-in">
                <FaRegLightbulb className="icon" />
                <div className="content">
                  <h3>1. Planning</h3>
                  <p>We define the project scope, objectives, and requirements.</p>
                </div>
              </div>
              <div className="step fade-in">
                <FaPencilRuler className="icon" />
                <div className="content">
                  <h3>2. Design</h3>
                  <p>We create system architectures and intuitive user interfaces.</p>
                </div>
              </div>
              <div className="step fade-in">
                <FaCode className="icon" />
                <div className="content">
                  <h3>3. Development</h3>
                  <p>Our team develops the project with a focus on quality and performance.</p>
                </div>
              </div>
              <div className="step fade-in">
                <FaBug className="icon" />
                <div className="content">
                  <h3>4. Testing</h3>
                  <p>We perform rigorous testing to ensure the product is bug-free.</p>
                </div>
              </div>
              <div className="step fade-in">
                <FaCloudUploadAlt className="icon" />
                <div className="content">
                  <h3>5. Deployment</h3>
                  <p>The final product is delivered to the client and deployed smoothly.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


     {/* Meet Our Team Section */}
{/* Meet Our Team Section */}
<section className="team">
  <div className="container">
    <h2 className="section-title">Meet Our Team</h2>
    <div className="team-members">
     
      <div className="team-member">
        <div className="team-image-container">
          <img src="/images/big-images/sidhu.png" alt="Sidhu Tiwari" className="team-image" />
        </div>
        <h4 className="team-name">Sidhu Kumar Tiwari</h4>
        <p className="team-role">CEO & Founder</p>
        <p className="team-qualification">
          B.Tech in Computer Science, MBA in IT Management. Total 14 Years of Corporate Experience.
        </p>
      </div>

     
      <div className="team-member">
        <div className="team-image-container">
          <img src="/images/big-images/manash.jpg" alt="Manash" className="team-image" />
        </div>
        <h4 className="team-name">Manash Tiwari</h4>
        <p className="team-role">Legal Advisor</p>
        <p className="team-qualification">
          LLB with expertise in technology law, data protection, and intellectual property rights.
        </p>
      </div>
    </div>
  </div>
</section>


      <div className="banner">
  <img src="/images/big-images/11.jpg" alt="Banner Background" className="banner-image" />
  <div className="banner-overlay">
    <div className="banner-content">
      <h1>Transforming Ideas into Reality</h1>
      <p>
        Partner with Encoders Software to bring your innovative concepts to life. 
        Let’s collaborate to create exceptional IT solutions tailored for your business.
      </p>
      

      <button onClick={handleNavigate} className="contact-us-button">
      Contact Us
      </button>
    </div>
  </div>
</div>

      <Footer />
    </div>
  );
};

export default About;
