import React from "react";
import "./PrivacyPolicy.css";
import Footer from "../../include/Footer";

const PrivacyPolicy = () => {
  return (
    <div>
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        Encoders Software Private Limited values your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard your data.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        We may collect personal information such as your name, email address, phone number, and company details when you interact with our services or website. We also collect non-personal data like IP addresses and browser types for analytical purposes.
      </p>

      <h2>2. How We Use Your Information</h2>
      <p>
        Your information is used to:
      </p>
      <ul>
        <li>Provide and improve our services.</li>
        <li>Respond to your inquiries and support requests.</li>
        <li>Send important updates or promotional materials.</li>
        <li>Analyze website usage and improve user experience.</li>
      </ul>

      <h2>3. Sharing Your Information</h2>
      <p>
        We do not sell or rent your personal information. However, we may share your data with trusted third-party service providers to perform services on our behalf, such as hosting or analytics, under strict confidentiality agreements.
      </p>

      <h2>4. Security</h2>
      <p>
        We implement robust security measures to protect your personal information from unauthorized access, alteration, or disclosure. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.
      </p>

      <h2>5. Cookies</h2>
      <p>
        Our website uses cookies to enhance your browsing experience. You can manage your cookie preferences through your browser settings.
      </p>

      <h2>6. Changes to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy periodically. Any changes will be reflected on this page with an updated revision date.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy, please contact us at <a href="mailto:support@encoderssoftware.com">support@encoderssoftware.com</a>.
      </p>
   
    </div>
       <Footer></Footer>
    </div>
  );
};

export default PrivacyPolicy;
