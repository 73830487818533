import React from 'react';

const Slider = () => {
  return (
    <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active" data-bs-interval="3000">
          <img
            src="/images/big-images/b2.jpg"
            className="d-block w-100"
            alt="Website Development Services"
          />
          <div className="carousel-caption d-none d-md-block">
            <h5>Innovative Website Development</h5>
            <p>
              Crafting dynamic and responsive websites tailored to your business
              needs. Build your online presence with EncodersSoftware.
            </p>
          </div>
        </div>
        <div className="carousel-item" data-bs-interval="3000">
          <img
            src="/images/big-images/b3.jpg"
            className="d-block w-100"
            alt="Mobile Application Development Services"
          />
          <div className="carousel-caption d-none d-md-block">
            <h5>Mobile Application Solutions</h5>
            <p>
              Delivering user-friendly mobile applications with seamless
              performance. Transform your ideas into reality with our expertise.
            </p>
          </div>
        </div>
        <div className="carousel-item" data-bs-interval="3000">
          <img
            src="/images/big-images/b4.jpg"
            className="d-block w-100"
            alt="Custom IT Solutions and SaaS Products"
          />
          <div className="carousel-caption d-none d-md-block">
            <h5>Customized IT Solutions</h5>
            <p>
              Empower your business with tailored SaaS products and efficient IT
              support services. Partner with EncodersSoftware for excellence.
            </p>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleDark"
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Slider;
