import React from 'react'
import About from './components/pages/About'
import Contact from './components/pages/Contact'
import Home from './components/pages/Home'
import Careers from './components/pages/Careers'
import ApplyForm from './components/pages/ApplyForm'
import { useNavigate } from "react-router-dom";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './include/Navbar';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import TermsOfUse from "./components/pages/TermsOfUse";

import ScrollToTop from './include/ScrollToTop'
import "@fortawesome/fontawesome-free/css/all.min.css";




const App = () => {

 
  return (

    
    <Router>
      <div>
     
      <ScrollToTop></ScrollToTop>
     <Navbar></Navbar>

 
  

     <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/apply" element={<ApplyForm />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </div>

    </Router>
  )
}

export default App
