import React from "react";
import "./SearchBar.css";

const FilterPanel = ({ filters, setFilters }) => {
  // Function to update filters
  const handleFilterChange = (filterKey, value) => {
    setFilters((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  };

  return (
    <div className="filter-panel">
      <div className="filter-header">
        <h3>Filters</h3>
        <div className="filter-options">
          <div className="filter-item">
            <label>
              <input
                type="checkbox"
                checked={filters.remote}
                onChange={(e) => handleFilterChange("remote", e.target.checked)}
              />
              Remote Jobs
            </label>
          </div>
          <div className="filter-item">
            <label>
              <input
                type="checkbox"
                checked={filters.fullTime}
                onChange={(e) => handleFilterChange("fullTime", e.target.checked)}
              />
              Full-Time
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterPanel;
