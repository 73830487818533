import React from "react";
import "./Home.css";
import Footer from "../../include/Footer";
import Navbar from "../../include/Navbar";
import Slider from '../../include/Slider';
import  { useState, useEffect,useRef } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {




const [activeCard, setActiveCard] = useState(null);


const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/contact"); 
  };

const handleCardClick = (e) => {
  const card = e.currentTarget;
  if (card.classList.contains("zoom")) {
    card.classList.remove("zoom");
  } else {
    card.classList.add("zoom");
  }
};

  const sliderRef = useRef(null);




  const handleScrollLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft -= 300; // Adjust scroll distance as needed
    }
  };

  const handleScrollRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollLeft += 300; // Adjust scroll distance as needed
    }
  };





  const services = [
    {
      title: "Mobile Application Development",
      image: "/images/big-images/mobile_devlopment.jpg",
      description:
        "High-quality mobile apps tailored to your business for Android, iOS, and Windows platforms.",
    },
    {
      title: "Web Application Development",
      image: "/images/big-images/web_dev.png",
      description:
        "ERP systems, e-commerce platforms, and customized web applications for streamlined operations.",
    },
    {
      title: "Corporate Training",
      image: "/images/big-images/cor_tra.webp",
      description:
        "Upskill your team with training in AI, ML, cloud computing, and other cutting-edge technologies.",
    },
    {
      title: "Educational Projects",
      image: "/images/big-images/cyber_sec.avif",
      description:
        "Live projects for schools, colleges, and universities to provide hands-on technical experience.",
    },
    {
      title: "Government Projects",
      image: "/images/big-images/govt_pro.webp",
      description:
        "Secure, scalable solutions for government initiatives ensuring efficiency and transparency.",
    },
    {
      title: "Cybersecurity Services",
      image: "/images/big-images/cyber_sec.avif",
      description:
        "Advanced security solutions to protect against evolving cyber threats and vulnerabilities.",
    },
    {
      title: "AI & Machine Learning",
      image: "/images/big-images/ai_machine.webp",
      description:
        "Boost efficiency and innovation with AI-driven solutions tailored for your business.",
    },
    {
      title: "Affordable Hosting",
      image: "/images/big-images/web-host.svg",
      description:
        "Partnering with trusted hosting providers to deliver reliable and cost-effective hosting solutions.",
    },
  ];

  const projects = [
    {
      title: "Delivery On Wheel",
      description: "Ranchi's first delivery app enhancing local logistics.",
      image: "/images/big-images/13.jpg",
    },
    {
      title: "Drop of Life",
      description: "A platform to facilitate blood donations between users.",
      image: "/images/big-images/dol.jpg",
    },
    {
      "title": "Account General Office ERP Application for GEMS",
      "description": "This application was focused on accounting and ERP to manage and generate payslips for employees of different government departments.",
      "image": "/images/big-images/12.jpg"
    },
    {
      title: "BITOSA Website",
      description: "Website for B.I.T Mesra alumni association.",
      image: "/images/big-images/bitosa.jpg",
    },
    {
      "title": "Ranchi University Online Fee Payment Portal",
      "description": "This portal was developed to facilitate online fee payment for Ranchi University students across various streams and departments. ",
      "image": "/images/big-images/ranchi-uni.jpg"
    }
  ];

  const testimonials = [
  
    { id: 1, message: "Delivery on Wheels provided seamless logistics and efficiency improvements.", client: "Apurv Jha" },
    { id: 2, message: "AG Office Gems Project streamlined our accounting operations with precision.", client: "VS Verma" },
    { id: 3, message: "The Ranchi University Online Fee Payment Portal revolutionized fee collection for us.", client: "PNB Bank" },
    { id: 4, message: "Drop of Life made water accessibility projects highly effective and impactful.", client: "Palamu DC" },
  ];
  const latestNews = [
    { id: 1, title: "New Feature Released", description: "Our latest feature is now live!", createdDate: "21-11-2024 10:30" },
    { id: 2, title: "Upcoming Webinar", description: "Join us for an insightful session on AI.", createdDate: "21-11-2024 10:30" },
    { id: 3, title: "Product Update", description: "We've enhanced our platform's security.", createdDate: "21-11-2024 10:30" },
    { id: 4, title: "Customer Success Story", description: "Learn how we transformed a business.", createdDate: "21-11-2024 10:30" },
    { id: 5, title: "Full-Stack Development Training", description: "We are going to provide full-stack development training starting from 20th January 2025.", createdDate: "21-11-2024 10:30" },
    { id: 6, title: "Real-World Full-Stack Training", description: "Complete training on real-world full-stack applications using Java Spring Boot + ReactJS and Node.js + ReactJS.", createdDate: "21-11-2024 10:30" },
    { id: 7, title: "Cloud Hosting and CI/CD Training", description: "Real-world training on hosting applications using AWS, Azure, and GCP, integrated with CI/CD pipelines for efficient deployment.", createdDate: "21-11-2024 10:30" },
  ];

  const faqs = [
    {
      id: 1,
      question: "What services do you provide?",
      answer: "We offer a wide range of services, including web development, mobile app development, AI and machine learning solutions, cloud hosting, ERP systems, cybersecurity services, and corporate training.",
    },
    {
      id: 2,
      question: "How can I get in touch?",
      answer: "You can contact us via email, phone, or through our website's contact form. We also respond promptly to inquiries on our social media platforms.",
    },
    {
      id: 3,
      question: "Do you provide support?",
      answer: "Yes, we provide 24/7 support for all our products and services. Our dedicated support team ensures that your queries and issues are resolved quickly.",
    },
    {
      id: 4,
      question: "Do you provide training programs?",
      answer: "Yes, we offer corporate training sessions, internship opportunities, and real-world project-based training. Our programs cover full-stack development, AI/ML, cloud computing, and much more.",
    },
    {
      id: 5,
      question: "What technologies do you specialize in?",
      answer: "We specialize in a wide range of technologies, including Java Spring Boot, ReactJS, Node.js, Python, machine learning frameworks, and cloud platforms like AWS, Azure, and GCP.",
    },
    {
      id: 6,
      question: "Can you help with government projects?",
      answer: "Absolutely! We have experience delivering secure, scalable solutions tailored to government requirements. Our solutions enhance transparency and operational efficiency.",
    },
    {
      id: 7,
      question: "What industries do you serve?",
      answer: "We serve diverse industries, including education, healthcare, logistics, e-commerce, and government sectors, offering customized solutions to address unique challenges in each domain.",
    },
    {
      id: 8,
      question: "What is your development process?",
      answer: "Our development process includes detailed requirements gathering, UI/UX design, agile development, rigorous testing, deployment, and ongoing maintenance to ensure the highest quality solutions.",
    },
    {
      id: 9,
      question: "Do you provide customized solutions?",
      answer: "Yes, we provide tailored solutions based on your specific business needs. We collaborate closely with you to understand your goals and deliver a product that exceeds expectations.",
    },
    {
      id: 10,
      question: "Can you integrate AI into existing systems?",
      answer: "Yes, we can seamlessly integrate AI technologies into your existing systems. Our solutions include predictive analytics, natural language processing, and automated workflows to enhance your operations.",
    },
    {
      id: 11,
      question: "Do you offer cloud hosting services?",
      answer: "Yes, we provide affordable and secure cloud hosting solutions using platforms like AWS, Azure, and GCP. We also ensure reliable deployment using CI/CD pipelines.",
    },
    {
      id: 12,
      question: "Can you help with e-commerce platforms?",
      answer: "Yes, we design and develop customized e-commerce platforms with features like inventory management, payment gateway integration, and seamless user experiences.",
    },
    {
      id: 13,
      question: "Do you support legacy system modernization?",
      answer: "Yes, we assist in upgrading legacy systems to modern platforms, ensuring minimal disruption and improved performance and scalability.",
    },
    {
      id: 14,
      question: "What is your pricing model?",
      answer: "Our pricing is flexible and tailored to the scope of the project. We offer competitive rates without compromising on quality, ensuring value for your investment.",
    },
  ];
  
  const [expandedFaq, setExpandedFaq] = useState(null);

  const toggleFaq = (id) => {
    setExpandedFaq((prevId) => (prevId === id ? null : id));
  };


  return (
    <div>
 <Slider></Slider>
    <div className="homepage">
      {/* Banner Section */}
    

      {/* About Us Section */}
     {/* About Us Section */}
{/* About Us Section */}
<section className="about-us">
<h1>Welcome to Encoders Software</h1>
<p>Innovative IT Solutions for Your Digital Transformation</p>
  <h2>About Us</h2>
  <div className="about-us-cards">
    {/* About Company */}
    <div className="about-card rotate-in">
      <h3>About Our Company</h3>
      <p>
        Encoders Software Private Limited, established in 2019, is a premier IT solutions provider based in Jharkhand, India. 
        Registered under CIN U74999JH2019PTC012902 and governed by the Registrar of Companies (RoC-Jharkhand), we are a private, 
        non-government company categorized as a "Company Limited by Shares."
      </p>
      <ul>
        <li>Authorized Capital: ₹10,00,000</li>
        <li>Paid-Up Capital: ₹1,00,000</li>
        <li>Headquartered in Jharkhand, India</li>
        <li>Specialized in cutting-edge IT solutions</li>
      </ul>
    </div>

    {/* What We Do */}
    <div className="about-card rotate-in">
      <h3>What We Do</h3>
      <ul>
        <li>Commercial website development</li>
        <li>Mobile application development</li>
        <li>SaaS products and custom software solutions</li>
        <li>Government project support</li>
        <li>Corporate training and professional upskilling</li>
        <li>Advanced AI and machine learning integrations</li>
        <li>Cybersecurity and blockchain solutions</li>
      </ul>
    </div>

    {/* Our Mission */}
    <div className="about-card rotate-in">
      <h3>Our Mission</h3>
      <p>
        Our mission is to empower businesses through innovative IT solutions, offering tailored services that ensure seamless 
        digital transformation and sustainable growth. We aim to deliver excellence, quality, and timely results for our clients.
      </p>
    </div>

    {/* Why Choose Us */}
    <div className="about-card rotate-in">
      <h3>Why Choose Us</h3>
      <p>
        At Encoders Software, we combine traditional software engineering principles with cutting-edge technologies to provide 
        solutions that are robust, scalable, and secure. Our team ensures that every project meets and exceeds expectations.
      </p>
      <ul>
        <li>Experienced professionals</li>
        <li>Innovative and customized solutions</li>
        <li>Commitment to client success</li>
        <li>Scalable and secure systems</li>
      </ul>
    </div>
  </div>
</section>

<div className="banner">
  <img src="/images/big-images/1.jpg" alt="Banner Background" className="banner-image" />
  <div className="banner-overlay">
    <div className="banner-content">
    <h1>Innovate. Transform. Succeed.</h1>
      <p>
        At Encoders Software, we empower businesses to achieve their full potential 
        through cutting-edge technology and tailored IT solutions. Let us be your 
        partner in digital transformation.
      </p>
    
    </div>
  </div>
</div>




     {/* Our Services Section */}
<section className="services">
  <h2>Our Services</h2>
  <div className="services-grid">
    {services.map((service, index) => (
      <div
        key={index}
        className="service-card"
        onClick={(e) => handleCardClick(e)}
      >
        <img src={service.image} alt={service.title} className="service-image" />
        <div className="service-content">
          <h3 className="service-title">{service.title}</h3>
          <p className="service-description">{service.description}</p>
        </div>
      </div>
    ))}
  </div>
</section>

    {/* Recent Projects and Testimonials Section */}
    <section className="work-and-testimonials">
      <h2>Our Work and Testimonials</h2>

      {/* Recent Projects Row */}
      <div className="recent-projects">
        <h3>Our Recent Projects</h3>
        <div className="projects-container">
          <button className="scroll-button left" onClick={handleScrollLeft}>
            &#8592; {/* Left arrow */}
          </button>
          <div className="projects-slider" ref={sliderRef}>
            {projects.map((project) => (
              <div key={project.id} className="project-item">
                <img src={project.image} alt={project.title} />
                <h4>{project.title}</h4>
                <p>{project.description}</p>
              </div>
            ))}
          </div>
          <button className="scroll-button right" onClick={handleScrollRight}>
            &#8594; {/* Right arrow */}
          </button>
        </div>
      </div>

      {/* Testimonials Row */}
      <div className="testimonials">
      <h3>What Our Clients Say</h3>
      <div className="testimonials-container">
        {testimonials.map((testimonial) => (
          <div key={testimonial.id} className="testimonial-item">
            "{testimonial.message}" - {testimonial.client}
          </div>
        ))}
      </div>
    </div>
    </section>


<div className="banner">
  <img src="/images/big-images/11.jpg" alt="Banner Background" className="banner-image" />
  <div className="banner-overlay">
    <div className="banner-content">
      <h1>Transforming Ideas into Reality</h1>
      <p>
        Partner with Encoders Software to bring your innovative concepts to life. 
        Let’s collaborate to create exceptional IT solutions tailored for your business.
      </p>
      <button onClick={handleNavigate} className="contact-us-button">
      Contact Us
      </button>
      
    </div>
  </div>
</div>


{/* News and FAQ Section */}
<section className="stay-updated">
      {/* Latest News Section */}
      <div className="latest-news">
        <h3>Latest News</h3>
        <div className="news-container">
          {latestNews.map((news) => (
            <div key={news.id} className="news-item">
              <h4>{news.title}</h4>
              <p>{news.description}</p>
              <p>{news.createdDate}</p>
            </div>
          ))}
        </div>
      </div>

      {/* FAQs Section */}
      <div className="faqs">
        <h3>Quick FAQs</h3>
        {faqs.map((faq) => (
          <div
            key={faq.id}
            className={`faq-item ${expandedFaq === faq.id ? "expanded" : ""}`}
            onClick={() => toggleFaq(faq.id)}
          >
            <h4>{faq.question}</h4>
            {expandedFaq === faq.id && <p>{faq.answer}</p>}
          </div>
        ))}
      </div>
    </section>




      {/* Contact CTA Section */}
      <section className="contact-cta">
      <h2>Let’s Collaborate</h2>
      <p>Contact us today to discuss how we can help transform your ideas into reality.</p>
      <button onClick={handleNavigate} className="cta-button">
        Get in Touch
      </button>
    </section>
    </div>
    <Footer></Footer>
    </div>
  );
};

export default Home;
