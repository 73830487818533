import React from "react";
import "./TermsOfUse.css";
import Footer from "../../include/Footer";

const TermsOfUse = () => {
  return (
    <div>
    <div className="terms-of-use">
      <h1>Terms of Use</h1>
      <p>
        Welcome to Encoders Software Private Limited. By accessing or using our website, services, or products, you agree to comply with and be bound by these Terms of Use. If you do not agree to these terms, please do not use our services.
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing our website, you agree to be bound by these Terms of Use, all applicable laws, and regulations, and agree that you are responsible for compliance with any applicable local laws.
      </p>

      <h2>2. Use of Services</h2>
      <p>
        You agree to use our services only for lawful purposes and in a way that does not infringe the rights of others or restrict or inhibit anyone else's use and enjoyment of the services.
      </p>

      <h2>3. Intellectual Property</h2>
      <p>
        All content, trademarks, and data on this website, including software, databases, text, graphics, icons, and hyperlinks, are the property of Encoders Software Private Limited. You may not copy, reproduce, or distribute any content without prior written consent.
      </p>

      <h2>4. Limitation of Liability</h2>
      <p>
        Encoders Software Private Limited is not liable for any direct, indirect, incidental, or consequential damages resulting from the use of or inability to use our services or for the cost of procurement of substitute services.
      </p>

      <h2>5. Governing Law</h2>
      <p>
        These terms will be governed by and construed in accordance with the laws of India, and any disputes relating to these terms will be subject to the exclusive jurisdiction of the courts of Ranchi, Jharkhand.
      </p>

      <h2>6. Changes to Terms</h2>
      <p>
        Encoders Software Private Limited reserves the right to modify these terms at any time. Any changes will be effective immediately upon posting. Your continued use of the services constitutes your agreement to the modified terms.
      </p>

      <p>
        If you have any questions about these Terms of Use, please contact us at <a href="mailto:support@encoderssoftware.com">support@encoderssoftware.com</a>.
      </p>
     
    </div>
     <Footer></Footer>
    </div>
  );
};

export default TermsOfUse;
