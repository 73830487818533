import React from "react";
import { Link } from "react-router-dom";
import "./navbar.css";

const Navbar = () => {
  return (
    <header>
      {/* Top Bar */}
      <div className="top-bar bg-white py-2 shadow-sm">
        <div className="container d-flex justify-content-end align-items-center">
          {/* Right-aligned Contact Info */}
          <div className="contact-info d-flex align-items-center">
            <div className="me-4 d-flex align-items-center">
              {/* Phone Icon */}
              <i className="fa fa-phone me-2" style={{ color: "blue" }}></i>
              <a
                href="tel:+917258803502"
                className="text-decoration-none"
                style={{ color: "black" }}
              >
                +91-7258803502
              </a>
            </div>
            <div className="d-flex align-items-center">
              {/* Email Icon */}
              <i className="fa fa-envelope me-2" style={{ color: "blue" }}></i>
              <a
                href="mailto:support@encoderssoftware.com"
                className="text-decoration-none"
                style={{ color: "black" }}
              >
                support@encoderssoftware.com
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Navigation Bar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container">
          {/* Logo */}
          <Link to="/" className="navbar-brand">
            <img src="/logo.png" alt="Encoders Software Pvt Ltd" height="50" />
          </Link>

          {/* Collapse Button */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navbar Links */}
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link to="/" className="nav-link" style={{ color: "#333" }}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link" style={{ color: "#333" }}>
                  About Us
                </Link>
              </li>
              
              
            

              <li className="nav-item">
                <Link to="/Careers" className="nav-link" style={{ color: "#333" }}>
                Careers
                </Link>
              </li>
              

              <li className="nav-item">
                <Link to="/contact" className="nav-link" style={{ color: "#333" }}>
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
